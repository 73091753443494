body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.sidebar-toggle-button {
    border: none; /* Убираем границу */
    cursor: pointer; /* Курсор указывает на возможность взаимодействия */
    padding: 8px; /* Отступы вокруг содержимого кнопки */
    border-radius: 4px; /* Скругление углов */
    transition: right 0.3s; /* Анимация изменения позиции кнопки */
  }
  
  .sidebar-toggle-button:hover {
    background-color: #f0f0f0; /* Цвет фона при наведении */
  }
  
  .sidebar-toggle-button:focus {
    outline: none; /* Убираем обводку при фокусе */
  }

  .tabs {
    display: flex;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  
  .tabs button {
    padding: 10px 20px;
    cursor: pointer;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
  }
  
  .tabs button.active {
    background-color: #e0e0e0;
    border-bottom: none;
  }
  
  .tab-content {
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #fff;
  }
  