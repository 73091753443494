/* .cell-auto {
    background-color: blue;
    color: white;
  } */

  .table {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
  }
  
  .cell-header {
    text-align: left;
    background-color: #f2f2f2;
    padding: 10px;
    border: 1px solid #ddd;
    width: 100%;
    box-sizing: border-box;
  }
  
  .cell {
    padding: 10px;
    border-left: none; /* Убираем вертикальную границу между столбцами */
    border-right: none; /* Убираем вертикальную границу между столбцами */
    border-top: 1px solid #ddd; /* Оставляем горизонтальную границу сверху */
    border-bottom: 1px solid #ddd; /* Оставляем горизонтальную границу снизу */
    width: 50%;
    box-sizing: border-box;
  }

  .cell:first-child {
    border-left: 1px solid #ddd; /* Возвращаем левую границу для первой ячейки */
  }
  
  .cell:last-child {
    border-right: 1px solid #ddd; /* Возвращаем правую границу для последней ячейки */
  }
  
  input.cell,
  select.cell {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
  }
  
  td {
    vertical-align: top;
  }
  
  td.full-width {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
  }

  .save-button, .add-button {
    padding: 10px 20px;
    margin-bottom: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    color: #fff; /* Цвет текста кнопки */
  }
  
  .save-button svg, .add-button svg {
    fill: currentColor; /* чтобы иконка наследовала цвет текста кнопки */
  }
  
  /* Специфичные стили для каждой кнопки */
  .save-button {
    background-color: #007bff; /* Цвет фона кнопки */
  }
  
  .save-button:hover {
    background-color: #0056b3; /* Цвет фона кнопки при наведении */
  }
  
  .add-button {
    background-color: #7a7878; /* Цвет фона кнопки */
  }
  
  .add-button:hover {
    background-color: #2b2a2a; /* Цвет фона кнопки при наведении */
  }


  .table .cell-eco {
    background-color: green;
    color: white;
  }
  
  .table .cell-comfort {
    background-color: red;
    color: white;
  }
  
  .table .cell-auto {
    background-color: blue;
    color: white;
  }

  .table .cell-off {
    background-color: black;
    color: white;
  }
  
  .table .cell-on {
    background-color: white;
    color: black;
  }
  
  .table .cell-undefined {
    background-color: yellow;
    color: black;
  }


  .program-container {
    margin-bottom: 20px;
    position: relative;
    border: 1px solid #ccc;
    border-radius: 15px;
    overflow: hidden;
  }
  
  .program-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    margin-left: 10px;
    font-size: 1.5em;
    color: black;
  }

  .time-scale {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 0px;
    box-sizing: border-box;
  }
  
  .time-mark {
    font-size: 12px;
    text-align: center;
    flex: 1;
    position: relative;
  }
  
  .time-mark:not(:first-child)::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 10px;
    background-color: #000;
  }
  
  .schedule-bar {
    position: relative;
    width: 100%;
    height: 50px;
    border: none;
    overflow: hidden;
    margin-top: 10px;
  }
  
  .interval {
    position: absolute;
    height: 80%;
    cursor: pointer;
    z-index: 1;
  }
  
  .actions-container {
    margin-top: 10px;
  }

  .button-container {
    display: inline-block;
    margin-right: 10px;
  }
  
  .button-container  button {
    border: none;
    outline: none;
    background-color: transparent;
  }


.program-actions {
  display: flex;
  align-items: center;
}

.program-actions button {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 10px;
}
/* 
body, html, #root {
  height: 100%;
  margin: 0;
} */

.log-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.log-textarea {
  flex-grow: 1; /* Take up all available space */
  width: 100%;
  height: 100%;
  box-sizing: border-box; /* Ensure padding and borders are included in the element's total width and height */
  padding: 10px;
  font-family: monospace; /* Optional: for better readability of log text */
  resize: none; /* Prevent manual resizing */
  overflow: auto;
}

.modal-content {
  padding: 20px;
  max-width: 90vw; /* Увеличение ширины модального окна */
  max-height: 90vh; /* Увеличение высоты модального окна */
  overflow-y: auto; /* Добавляет полосу прокрутки по вертикали, если контент не помещается */
}

.interval-table-container {
  max-height: 300px; /* Выберите желаемую фиксированную высоту */
  overflow-y: auto; /* Добавляет полосу прокрутки по вертикали, если контент не помещается */
  width: 100%;
}

.interval-table-container.scrollable {
  overflow-y: auto;
}

.interval-table {
  width: 100%;
  border-collapse: collapse;
}

.interval-table th, .interval-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  font-size: 14px;
}

.interval-table th {
  background-color: #f2f2f2;
}

.interval-table input,
.interval-table select {
  width: 100%; /* Расширяет input и select на всю ширину ячейки */
  padding: 6px; /* Добавляет внутренний отступ для input и select */
  box-sizing: border-box; /* Учитывает padding и border в общей ширине элемента */
  font-size: 1em; /* Увеличивает размер шрифта */
}

@media (max-width: 600px) {
  .modal-content {
    padding: 8px;
    max-width: 95vw; /* Увеличение ширины модального окна на мобильных устройствах */
    max-height: 95vh; /* Увеличение высоты модального окна на мобильных устройствах */
  }

  .interval-table th, .interval-table td {
    padding: 4px; /* Уменьшает padding для лучшей компоновки на мобильных устройствах */
    font-size: 12px;
  }

  .StyledButton {
    font-size: 12px; /* Уменьшает размер шрифта для кнопок на мобильных устройствах */
    padding: 4px 8px; /* Уменьшает отступы в кнопках */
  }

  .StyledButtonDelete {
    padding: 2px 4px; /* Еще сильнее уменьшает отступы для кнопки Delete */
  }

  .interval-table input,
  .interval-table select {
    padding: 4px; /* Уменьшает padding для input и select на мобильных устройствах */
    font-size: 0.9em; /* Уменьшает размер шрифта на мобильных устройствах */
  }

  .interval-table th, .interval-table td {
    font-size: 0.9em; /* Уменьшает размер шрифта в таблице на мобильных устройствах */
  }

  .modal-content h2 {
    font-size: 1.2em; /* Уменьшает размер заголовка в модальном окне на мобильных устройствах */
  }
}

.progress-bar-container {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
}

.styled-progress-bar {
  width: 100%;
  height: 20px;
  appearance: none;
}

.styled-progress-bar::-webkit-progress-bar {
  background-color: #f3f3f3;
}

.styled-progress-bar::-webkit-progress-value {
  background-color: #4caf50;
}

.styled-progress-bar::-moz-progress-bar {
  background-color: #4caf50;
}